import reduce from 'lodash/reduce';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import slice from 'lodash/slice';
import { getValue } from '@emobg/web-utils';

export const GETTERS = {
  isAvailabilityLoading: 'isAvailabilityLoading',
  isAvailabilityFilterApplied: 'isAvailabilityFilterApplied',
  getAvailabilityResponse: 'getAvailabilityResponse',
  getAvailabilityRange: 'getAvailabilityRange',
  getLocations: 'getLocations',
  getOldCityValue: 'getOldCityValue',
  getOldProfileValue: 'getOldProfileValue',
  getOldBookingTypeValue: 'getOldBookingTypeValue',
  getOldStartValue: 'getOldStartValue',
  getOldEndValue: 'getOldEndValue',
  getBeforeAvailabilityActions: 'getBeforeAvailabilityActions',
  getAfterAvailabilityActions: 'getAfterAvailabilityActions',
  getEmployeeProfileUuid: 'getEmployeeProfileUuid',
  getEmployeeProfile: 'getEmployeeProfile',
  isSomeAvailabilityFilterApplied: 'isSomeAvailabilityFilterApplied',
  getCollapsedFilters: 'getCollapsedFilters',
  getNotCollapsedFilters: 'getNotCollapsedFilters',
  getCollapsedFiltersApplied: 'getCollapsedFiltersApplied',
  getFiltersAppliedCounter: 'getFiltersAppliedCounter',
};

export default {

  [GETTERS.isAvailabilityLoading](state) {
    return state.loading;
  },

  [GETTERS.getAvailabilityResponse](state) {
    return state.availables;
  },

  [GETTERS.getAvailabilityRange](state) {
    return {
      start_availability: state.availables.start_availability,
      end_availability: state.availables.end_availability,
    };
  },

  [GETTERS.getLocations](state) {
    return state.availables.locations;
  },

  [GETTERS.getOldCityValue](state) {
    return state.oldValues.city;
  },

  [GETTERS.getOldProfileValue](state) {
    return state.oldValues.profile;
  },

  [GETTERS.getOldBookingTypeValue](state) {
    return state.oldValues.bookingType;
  },

  [GETTERS.getOldStartValue](state) {
    return state.oldValues.start;
  },

  [GETTERS.getOldEndValue](state) {
    return state.oldValues.end;
  },

  [GETTERS.getBeforeAvailabilityActions](state) {
    return state.beforeActions;
  },

  [GETTERS.getAfterAvailabilityActions](state) {
    return state.afterActions;
  },

  [GETTERS.getEmployeeProfileUuid](state) {
    return state.employee.business_profile_uuid;
  },

  [GETTERS.getEmployeeProfile](state) {
    return state.employee;
  },

  [GETTERS.isSomeAvailabilityFilterApplied](state) {
    return state.filters.filter(i => i.applied.length).length;
  },

  [GETTERS.isAvailabilityFilterApplied]() {
    return filter => filter.applied && filter.applied.length;
  },

  [GETTERS.getCollapsedFilters](state) {
    const isNilValue = isNil(state.numberFiltersNotCollapsed);
    const isNaNValue = !isNumber(state.numberFiltersNotCollapsed);
    const isLowerThan0 = state.numberFiltersNotCollapsed < 0;

    if (isNilValue || isNaNValue || isLowerThan0 || !state.filtersAreCollapsed) {
      return [];
    }

    return slice(state.filters, state.numberFiltersNotCollapsed);
  },

  [GETTERS.getNotCollapsedFilters](state) {
    const isNilValue = isNil(state.numberFiltersNotCollapsed);
    const isNaNValue = !isNumber(state.numberFiltersNotCollapsed);
    const isLowerThan0 = state.numberFiltersNotCollapsed < 0;

    if (isNilValue || isNaNValue || isLowerThan0 || !state.filtersAreCollapsed) {
      return state.filters;
    }

    return slice(state.filters, 0, state.numberFiltersNotCollapsed);
  },

  [GETTERS.getCollapsedFiltersApplied](state) {
    const isNilValue = isNil(state.numberFiltersNotCollapsed);
    const isNaNValue = !isNumber(state.numberFiltersNotCollapsed);
    const isLowerThan0 = state.numberFiltersNotCollapsed < 0;

    if (isNilValue || isNaNValue || isLowerThan0 || !state.filtersAreCollapsed) {
      return [];
    }

    const filtersCollapsed = slice(state.filters, state.numberFiltersNotCollapsed);

    const filtersApplied = filtersCollapsed.filter(i => i.applied && i.applied.length > 0);

    return filtersApplied;
  },

  [GETTERS.getFiltersAppliedCounter](state) {
    return reduce(state.filters, (sum, filter) => sum + getValue(filter, 'applied.length', 0), 0);
  },
};
