export const GTM_EVENTS = {
  carsharingBookingCreated: 'carsharing_booking_created',
  b2BCompanyCreated: 'b2b_company_created',
  signUp: 'sign_up',
  b2BSignupAfterInvitation: 'b2b_signup_after_invitation',
  pageView: 'page_view',
  tfaVerificationCode: '2_fa_verification_code',
  tfaCodeSet: '2_fa_code_set',
  purchase: 'purchase',
  tfaResendCode: '2_fa_resend_code',
  viewItemList: 'view_item_list',
  selectItem: 'select_item',
  viewItem: 'view_item',
  addToCart: 'add_to_cart',
  removeFromCart: 'remove_from_cart',
  beginCheckout: 'begin_checkout',
  addPaymentInfo: 'add_payment_info',
  viewCart: 'view_cart',
  search: 'search',
  applyFilter: 'apply_filter',
  removeFilter: 'remove_filter',
};

export const GTM_MODAL_EVENTS = {
  addPaymentMethod: 'add-payment-method-modal',
  selectPaidTariffConfirmationModal: 'select-paid-tariff-confirmation-modal',
  selectPaidInsurancesConfirmationModal: 'select-paid-insurances-confirmation-modal',
  validationStatusModal: 'validation-status-modal',
  accountCreationConfirmationModal: 'account-creation-confirmation-modal',
  drivingLicenceValidationStep1: 'driving-licence-validation-step1',
  drivingLicenceValidationStep2: 'driving-licence-validation-step2',
  drivingLicenceValidationStep3: 'driving-licence-validation-step3',
  keycardViewInitialStep: 'keycard-view-initial-step',
  keycardViewLinkExisting: 'keycard-view-link-existing',
  newKeycardView: 'new-keycard-view',
  submitKeyCardConfiguration: 'submit-key-card-configuration',
  locationInfo: 'location-info',
  bookingCreated: 'booking-created',
  bookingSummaryInsurancesInfo: 'booking-summary-insurances-info',
  editBookingTime: 'edit-booking-time',
  editBookingInsurance: 'edit-booking-insurance',
  cancelBooking: 'cancel-booking',
  editBookingPeople: 'edit-booking-people',
  addCarpoolingToBooking: 'add-carpooling-to-booking',
  cancelBookingCarpooling: 'cancel-booking-carpooling',
  editEmployeeBookingTime: 'edit-employee-booking-time',
  editEmployeeBookingReturnLocation: 'edit-employee-booking-return-location',
  editEmployeeBookingPeople: 'edit-employee-booking-people',
  cancelEmployeeBooking: 'cancel-employee-booking',
  editEmployeePrebookingTime: 'edit-employee-prebooking-time',
  editEmployeePrebookingPeople: 'edit-employee-prebooking-people',
  editEmployeePrebookingReturnLocation: 'edit-employee-prebooking-return-location',
  cancelEmployeePrebooking: 'cancel-employee-prebooking',
  editEmployeeDrivingLicense: 'edit-employee-driving-license',
  editEmployeeBadge: 'edit-employee-badge',
  inviteEmployees: 'invite-employees',
  cancelAccount: 'cancel-account',
};

export const GTM_VIEW_ITEM_LIST_EVENT_KEYS = {
  itemId: 'item_id',
  itemName: 'item_name',
  itemCategory: 'item_category',
  itemCategory2: 'item_category2',
  itemCategory3: 'item_category3',
  itemBrand: 'item_brand',
  itemListId: 'item_list_id',
  itemListName: 'item_list_name',
  index: 'index',
  price: 'price',
};

export const GTM_FILTERS_EVENTS_KEYS = {
  filterType: 'filter_type',
  filterValue: 'filter_value',
};

export const GTM_SIGN_UP_EVENT_VALUES = {
  B2C: 'B2C',
  openB2B: 'Open B2B',
  dedicatedB2B: 'Dedicated B2B',
  adminB2B: 'Admin B2B',
};

export const GTM_SEARCH_EVENT_VALUES = {
  address: 'Address',
  favouriteLocation: 'Favourite location',
  corporateParking: 'Corporate Parking',
};
